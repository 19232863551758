<template>
  <div>
    <l-map ref="map" :zoom="zoom" :key="center[0]" :center="center"  v-if="map">
      <l-marker
        @update:latLng="updateMarker"
        :lat-lng="markerLatLng"
        draggable
        v-if="markerLatLng.length === 2"
      ></l-marker>
      <l-tile-layer :url="url" />
    </l-map>
    <b-row class="mt-1">
      <b-col cols="6">
        <form-input
          :disabled="true"
          label="خط العرض"
          v-model="markerPos.lat"
          rules="required"
        />
       
      </b-col>
      <b-col cols="6">
        <form-input
          :disabled="true"
          label="خط الطول"
          v-model="markerPos.lng"
          rules="required"
        />
      </b-col>
     
    </b-row>
  </div>
</template>
    
<script>

import LoaderBtn from "@/components/loader-btn.vue";
import { LMap, LTileLayer, LMarker, LCircle } from "vue2-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import FormInput from "@/components/form-input/index.vue";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
import { BCol, BRow,BButton } from "bootstrap-vue";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    BCol,
    BRow,
    FormInput,BButton,LoaderBtn
  },

  props: {
    lng: {
      type:String,
      default:"36.19846891224607"

    } ,
    lat: {
      type:String,
      default:"37.16204839923488"
    } ,
  },


  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 10,
      map: false,
      markerPos: {   
        lng: "36.19846891224607",
        lat: "37.16204839923488",
      },
      center: ["37.16204839923488" ,"36.19846891224607"],
      marker: ["37.16204839923488","36.19846891224607"],
    };
  },

  methods: {
    updateMarker(x) {
      this.markerPos = x;
      this.$emit("markerUpdated", this.markerPos);
    },

    setLngLat(lng, lat) {
      this.center = [lat, lng];
      this.markerLatLng =  [lat, lng];
      this.markerPos.lat=lat;
      this.markerPos.lng=lng;
    },
  },

  // mounted() {
  //   this.$nextTick(() => {
  //     setTimeout(() => {
  //       this.map = true;
  //     }, 100);
  //     // this.map = true; // work as expected
  //   });
  // },

  // watch: {
  //   lng(val) {
  //     this.setLngLat(val, this.lat);
  //   },
  //   lat(val) {
  //     this.setLngLat(this.lng, val);
  //   },
  // },
  created(){
    this.setLngLat(this.lng, this.lat);
    this.$nextTick(() => {
      setTimeout(() => {
        this.map = true;
      }, 100);
      // this.map = true; // work as expected
    });
  }
};
</script>
    
<style lang="scss">
@import "@core/scss/vue/libs/map-leaflet.scss";

.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;

  }
}
</style>
    